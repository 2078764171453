// Code generated by Slice Machine. DO NOT EDIT.

import { defineAsyncComponent } from "vue";
import { defineSliceZoneComponents } from "@prismicio/vue";

export const components = defineSliceZoneComponents({
  blog_content: defineAsyncComponent(() => import("./BlogContent/index.vue")),
  blog_hub_hero: defineAsyncComponent(() => import("./BlogHubHero/index.vue")),
  blog_quote: defineAsyncComponent(() => import("./BlogQuote/index.vue")),
  blog_second_title: defineAsyncComponent(
    () => import("./BlogSecondTitle/index.vue"),
  ),
  faq_hero: defineAsyncComponent(() => import("./FaqHero/index.vue")),
  faq_section: defineAsyncComponent(() => import("./FaqSection/index.vue")),
  find_property_process: defineAsyncComponent(
    () => import("./FindPropertyProcess/index.vue"),
  ),
  hero: defineAsyncComponent(() => import("./Hero/index.vue")),
  not_found: defineAsyncComponent(() => import("./NotFound/index.vue")),
});
